import React from 'react'
import './contact.css'

function Contact() {
  return (
    <div className='form-section'>
            <div className="container">
                <div className="content">
                    <div className="left-side">
                        <div className="address details">
                        <ion-icon name="map"></ion-icon>
                            <div className="topic">Address</div>
                            <div className="text-one">1,2 Maa Durga Nagar,Balaji Vihar indore(M.P) India
</div>
                            <div className="text-two">Pin code-452001</div>
                        </div>
                        <div className="phone details">
                        <ion-icon name="call"></ion-icon>
                            <div className="topic">Phone</div>
                            <div className="text-one"><a href="tel: +91 8349029760"> +91 8349029760</a></div>
                            {/* <div className="text-two">+91 9999999999</div>
                            <div className="text-two">+91 9999999999</div> */}
                        </div>
                        <div className="email details">
                        <ion-icon name="mail"></ion-icon>
                            <div className="topic">Email</div>
                            <div className="text-one"><a href="mailto:keska.globaltrade@gmail.com">keska.globaltrade@gmail.com</a></div>
                        </div>
                    </div>
                    <div className="right-side">
                        <div className="topic-text">Send us a message</div>
                        <p>For any queries related to our products,please send us a message .It would be our pleasure to help you</p>
                        <form  action="https://formspree.io/f/xbjnkywv"
  method="POST">
                            <div className="input-box">
                                <input type="text" name='name'  placeholder="Enter your name" />
                            </div>
                            <div className="input-box">
                                <input type="text" name='email' placeholder="Enter your email" />
                            </div>
                            <div name='massage' className="input-box message-box">
                                <textarea placeholder='Enter your message'/>
                            </div>
                            <div className="button">
                                <input  type="submit" value="Send Now" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Contact
