import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='foot'>
      <section className="footer-container">
        <footer>
          <div className="content">
            <div className="top">
              <div className="logo-details">
                <i className="fab fa-slack"></i>
                <span className="logo_name">KESKA GLOBAL TRADE</span>
              </div>
              <div className="media-icons">
                <Link to="https://www.facebook.com/people/Keska-Global-Trade/61557056499331/"><ion-icon name="logo-facebook"></ion-icon></Link>
                <Link to="https://www.instagram.com/keskaglobaltrade/"><ion-icon name="logo-instagram"></ion-icon></Link>
                {/* <Link><ion-icon name="logo-linkedin"></ion-icon></Link>
                <Link><ion-icon name="logo-youtube"></ion-icon></Link> */}
                
              </div>
            </div>
            <div className="link-boxes">
              <ul className="box">
                <li className="link_name">About Us</li>
                <li>
                  <Link to="#"
                    >As a team dedicated to excellence, we at Keska Global Trade
                    blend passion with precision to deliver the finest Agro Prodduct from
                    around the world. Our commitment is your culinary delight.</Link>
                </li>
              </ul>
              <ul className="box">
                <li className="link_name">Company</li>
                <li><a href='mailto: keska.globaltrade@gmail.com'>Email:keska.globaltrade@gmail.com</a></li>
                <li><a href='tel:+91 8349029760'>Phone No.=+91 8349029760</a></li>
                <li><Link to="/about">Address: 1,2 Maa Durga Nagar,Balaji Vihar indore(M.P) India Pin code-452001</Link></li>
              </ul>
              <ul className="box">
                <li className="link_name">UseFull Links</li>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/contact">Contact us</Link></li>
                <li><Link to="/about">About us</Link></li>
                <li><Link to="/product">Our product</Link></li>
              </ul>
              <ul className="box">
                <li className="link_name">Terms</li>
                <li><Link to="/term">Terms & Condition</Link></li>
                <li><Link to="/privacpolicy">Privacy policy</Link></li>
              </ul>
              <ul className="box input-box">
                <li className="link_name">Subscribe</li>
                <li><input type="text" placeholder="Enter your email" /></li>
                <li ><input type="button" value="Subscribe" /></li>
              </ul>
            </div>
          </div>
          <div className="bottom-details">
            <div className="bottom_text">
              <span className="copyright_text"
                >Design By © 2024 <Link to="https://dgmt.in/">DGMT.In.Pvt.Ltd</Link></span>
              <span className="policy_terms">
              <Link to="/term">Terms & Condition</Link>
              <Link to="/privacpolicy">Privacy policy</Link>
              </span>
            </div>
          </div>
        </footer>
      </section>
    </div>
  );
}

export default Footer;
