import React from 'react'

const Certificate = () => {
  return (
    <div className='w-full h-full flex items-center justify-center'>
        <img src="https://i.ibb.co/Mh16NZY/be10f363-b17a-4f07-b276-3742d7ec816e.jpg" alt="certificate" />
    </div>
  )
}

export default Certificate